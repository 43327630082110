<template>
  <c-box mb="28px">
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
      class="container"
    >
      <c-flex py="25px" px="27px" border-bottom="1px solid #C4C4C4">
        <c-flex justify="space-between" align="center" flex-grow="1">
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            Demographic Analytic
          </c-heading>
        </c-flex>
      </c-flex>

      <c-box>
        <c-flex>
          <c-button
            size="md"
            height="58px"
            flexGrow="1"
            :color="!type ? 'brand.900' : 'darkGray.900'"
            fontSize="16px"
            lineHeight="24px"
            border-radius="0"
            :background="!type ? 'white' : 'superLightGray.900'"
            border-color="green.500"
            v-chakra="{
              '&:hover': {
                background: '#F2F2F2',
                borderRadius: '0',
              },
              '&:focus, &:active': {
                color: 'brand.900',
                background: 'white',
                borderRadius: '0',
                boxShadow: 'none',
              },
            }"
            @click="onClientType(null)"
          >
            Type Client
          </c-button>
          <c-divider m="0" border-color="lightGray" orientation="vertical" />
          <c-button
            size="md"
            height="58px"
            flexGrow="1"
            :color="type ? 'brand.900' : 'darkGray.900'"
            fontSize="16px"
            lineHeight="24px"
            border-radius="0"
            :background="type ? 'white' : 'superLightGray.900'"
            border-color="green.500"
            v-chakra="{
              '&:hover': {
                background: '#F2F2F2',
                borderRadius: '0',
              },
              '&:focus, &:active': {
                color: 'brand.900',
                background: 'white',
                borderRadius: '0',
                boxShadow: 'none',
              },
            }"
            @click="onClientType('paying')"
          >
            Type Paying Clients
          </c-button>
        </c-flex>
        <c-grid
          w="100%"
          :template-columns="'repeat(' + headings.length + ', 0fr)'"
        >
          <c-box
            v-for="(item, index) in headings"
            :key="'heading-' + index"
            :w="item.width"
            :text-align="
              index === 0 || index === 4 || index === 5 ? 'center' : ''
            "
            py="11px"
            px="14px"
            color="brand.900"
            font-weight="bold"
            font-size="12px"
            line-height="18px"
            textTransform="uppercase"
            bg="rgba(0, 140, 129, 0.1)"
            display="flex"
            align-items="center"
          >
            {{ item.label }}
          </c-box>
        </c-grid>

        <c-grid
          w="100%"
          max-w="100%"
          :template-columns="'repeat(' + headings.length + ', 0fr)'"
          border-bottom="1px solid #C4C4C4"
          v-for="(item, idx) in items"
          :key="idx"
        >
          <c-box
            :w="headings[0].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            justify-content="center"
          >
            {{ numberingRow(idx) }}
          </c-box>
          <c-box
            :w="headings[1].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            text-transform="capitalize"
          >
            {{ item.country ? item.country : "-" }}
          </c-box>
          <c-box
            :w="headings[2].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            text-transform="capitalize"
          >
            {{ item.province ? item.province : "-" }}
          </c-box>
          <c-box
            :w="headings[3].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            text-transform="capitalize"
          >
            {{ item.city ? item.city : "-" }}
          </c-box>
          <c-box
            v-if="headings[4]"
            :w="headings[4].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            justify-content="center"
            text-transform="capitalize"
          >
            {{ item.gender ? item.gender : "-" }}
          </c-box>
          <c-box
            v-if="headings[5]"
            :w="headings[5].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            justify-content="center"
            text-transform="capitalize"
          >
            {{ item.age ? item.age : "-" }}
          </c-box>
          <c-box
            v-if="headings[6]"
            :w="headings[6].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            text-transform="capitalize"
          >
            {{ item.job ? item.job : "-" }}
          </c-box>
          <c-box
            v-if="headings[7]"
            :w="headings[7].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            justify-content="center"
            text-transform="capitalize"
          >
            {{ item.lastEducation ? item.lastEducation : "-" }}
          </c-box>
        </c-grid>

        <c-flex v-if="!isEmpty" justify="space-between" py="28px" px="40px">
          <c-flex align="center">
            <c-text color="gray.900" fontSize="14px" lineHeight="21px">
              Show:
            </c-text>
            <c-select
              v-model="perPage"
              min-w="120px"
              border-radius="6px"
              fontSize="14px"
              ml="13px"
              size="md"
            >
              <option value="5">5 Rows</option>
              <option value="10">10 Rows</option>
              <option value="15">15 Rows</option>
              <option value="20">20 Rows</option>
            </c-select>
          </c-flex>

          <c-flex justifyContent="flex-end" w="100%">
            <Pagination
              :totalPages="totalPages"
              :perPage="parseInt(this.perPage)"
              :currentPage="currentPage"
              :maxVisibleButtons="3"
              @pagechanged="onChangePage"
            />
          </c-flex>
        </c-flex>
      </c-box>
    </c-box>
  </c-box>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";
import Pagination from "@/components/pagination";
export default {
  name: "DashboardDemographicAnalytic",
  mixins: [generalMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: "5",
      type: null,
    };
  },
  computed: {
    ...mapState({
      items: (s) => s.suDashboard.demographic,
      meta: (s) => s.suDashboard.demographicMeta,
      headings() {
        if (!this.type) {
          return [
            { label: "no", width: "74px" },
            { label: "negara", width: "275px" },
            { label: "provinsi", width: "300px" },
            { label: "kota", width: "243px" },
          ];
        } else {
          return [
            { label: "no", width: "74px" },
            { label: "negara", width: "120px" },
            { label: "provinsi", width: "140px" },
            { label: "kota", width: "133px" },
            { label: "jenis kelamin", width: "100px" },
            { label: "usia", width: "65px" },
            { label: "pekerjaan", width: "160px" },
            { label: "pendidikan", width: "100px" },
          ];
        }
      },
    }),
    isEmpty() {
      return this.items.length === 0;
    },
    totalPages() {
      return Math.ceil(this.meta?.total / parseInt(this.perPage));
    },
  },
  watch: {
    perPage: {
      handler(val) {
        this.perPage = val;
        this.onLoadData();
      },
    },
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions({
      loadDemographicAnalytic: "suDashboard/listDemographicAnalytic",
    }),
    getFormatDate(date) {
      if (!date || date === "-") return date;
      moment.locale("id");
      return moment(date).format("D MMM yyyy");
    },
    numberingRow(index) {
      return this.currentPage * this.perPage - this.perPage + index + 1;
    },
    onChangePage(page) {
      this.currentPage = page;
      this.onLoadData();
    },
    onClientType(type) {
      this.type = type;
      this.currentPage = 1;
      this.onLoadData();
    },
    onLoadData() {
      this.loadDemographicAnalytic({
        type: this.type,
        page: this.currentPage,
        perpage: parseInt(this.perPage),
      });
    },
  },
};
</script>