<template>
  <c-box mb="28px">
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
      class="container"
    >
      <c-flex py="25px" px="27px" border-bottom="1px solid #C4C4C4">
        <c-flex justify="space-between" align="center" flex-grow="1">
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            Clients Tracking
          </c-heading>
        </c-flex>
      </c-flex>

      <c-box>
        <c-grid
          w="100%"
          :template-columns="'repeat(' + headings.length + ', 0fr)'"
        >
          <c-box
            v-for="(item, index) in headings"
            :key="'heading-' + index"
            :w="item.width"
            :text-align="index === 0 || index === 2 ? 'center' : ''"
            py="11px"
            px="14px"
            color="brand.900"
            font-weight="bold"
            font-size="12px"
            line-height="18px"
            textTransform="uppercase"
            bg="rgba(0, 140, 129, 0.1)"
          >
            {{ item.label }}
          </c-box>
        </c-grid>

        <c-grid
          w="100%"
          max-w="100%"
          :template-columns="'repeat(' + headings.length + ', 0fr)'"
          border-bottom="1px solid #C4C4C4"
          v-for="(item, idx) in items"
          :key="idx"
        >
          <c-box
            :w="headings[0].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            justify-content="center"
          >
            {{ numberingRow(idx) }}
          </c-box>
          <c-box
            :w="headings[1].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ item.fullName }}
          </c-box>
          <c-box
            :w="headings[2].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            justify-content="center"
          >
            <c-box
              font-size="10px"
              line-height="15px"
              text-transform="uppercase"
              py="2px"
              px="10px"
              border="1px solid transparent"
              :color="
                item.paymentStatus === 'success'
                  ? '#0075E1'
                  : item.paymentStatus === 'pending'
                  ? '#FBBC05'
                  : item.paymentStatus === 'failed'
                  ? '#EA4335'
                  : 'inherit'
              "
              :background="
                item.paymentStatus === 'success'
                  ? 'rgba(0, 117, 225, 0.1)'
                  : item.paymentStatus === 'pending'
                  ? 'rgba(251, 188, 5, 0.1)'
                  : item.paymentStatus === 'failed'
                  ? 'rgba(234, 67, 53, 0.1)'
                  : 'inherit'
              "
              :borderColor="
                item.paymentStatus === 'success'
                  ? '#0075E1'
                  : item.paymentStatus === 'pending'
                  ? '#FBBC05'
                  : item.paymentStatus === 'failed'
                  ? '#EA4335'
                  : ''
              "
              border-radius="35px"
            >
              {{ item.paymentStatus }}
            </c-box>
          </c-box>
          <c-box
            :w="headings[3].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ item.program }}
          </c-box>
          <c-box
            :w="headings[4].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            textTransform="capitalize"
          >
            {{ item.nutritionist }}
          </c-box>
          <c-box
            :w="headings[5].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            flex-wrap="wrap"
          >
            {{ getFormatDate(item.programEndDate) }}
            <c-text color="gray" flex-basis="100%">
              {{ item.programEndInDays }}
            </c-text>
          </c-box>
        </c-grid>

        <c-flex v-if="!isEmpty" justify="space-between" py="28px" px="40px">
          <c-flex align="center">
            <c-text color="gray.900" fontSize="14px" lineHeight="21px">
              Show:
            </c-text>
            <c-select
              v-model="perPage"
              min-w="120px"
              border-radius="6px"
              fontSize="14px"
              ml="13px"
              size="md"
            >
              <option value="5">5 Rows</option>
              <option value="10">10 Rows</option>
              <option value="15">15 Rows</option>
              <option value="20">20 Rows</option>
            </c-select>
          </c-flex>

          <c-flex justifyContent="flex-end" w="100%">
            <Pagination
              :totalPages="totalPages"
              :perPage="parseInt(this.perPage)"
              :currentPage="currentPage"
              :maxVisibleButtons="3"
              @pagechanged="onChangePage"
            />
          </c-flex>
        </c-flex>
      </c-box>
    </c-box>
  </c-box>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";
import Pagination from "@/components/pagination";
export default {
  name: "DashboardClientTracking",
  mixins: [generalMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      headings: [
        { label: "no", width: "74px" },
        { label: "nama klien", width: "160px" },
        { label: "status pembayaran", width: "180px" },
        { label: "program", width: "142px" },
        { label: "ahli gizi", width: "180px" },
        { label: "berakhir", width: "156px" },
      ],
      currentPage: 1,
      perPage: "5",
    };
  },
  computed: {
    ...mapState({
      items: (s) => s.suDashboard.clients,
      meta: (s) => s.suDashboard.clientMeta,
    }),
    isEmpty() {
      return this.items.length === 0;
    },
    totalPages() {
      return Math.ceil(this.meta?.total / parseInt(this.perPage));
    },
  },
  watch: {
    perPage: {
      handler(val) {
        this.loadClientsTracking({
          page: this.currentPage,
          perpage: parseInt(val),
        });
      },
    },
  },
  mounted() {
    this.loadClientsTracking({
      page: this.currentPage,
      perpage: parseInt(this.perPage),
    });
  },
  methods: {
    ...mapActions({
      loadClientsTracking: "suDashboard/listClientsTracking",
    }),
    getFormatDate(date) {
      if (!date || date === "-") return date;
      moment.locale("id");
      return moment(date).format("D MMM yyyy");
    },
    numberingRow(index) {
      return this.currentPage * this.perPage - this.perPage + index + 1;
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadClientsTracking({
        page: this.currentPage,
        perpage: parseInt(this.perPage),
      });
    },
  },
};
</script>