<template>
  <c-box mb="28px">
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
      class="container"
    >
      <c-flex py="25px" px="27px" border-bottom="1px solid #C4C4C4">
        <c-flex justify="space-between" align="center" flex-grow="1">
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            Coupons Tracking
          </c-heading>
        </c-flex>
      </c-flex>

      <c-box>
        <c-grid
          w="100%"
          :template-columns="'repeat(' + headings.length + ', 0fr)'"
        >
          <c-box
            v-for="(item, index) in headings"
            :key="'heading-' + index"
            :w="item.width"
            py="11px"
            px="14px"
            color="brand.900"
            font-weight="bold"
            font-size="12px"
            line-height="18px"
            textTransform="uppercase"
            bg="rgba(0, 140, 129, 0.1)"
          >
            {{ item.label }}
          </c-box>
        </c-grid>

        <c-grid
          w="100%"
          max-w="100%"
          :template-columns="'repeat(' + headings.length + ', 0fr)'"
          border-bottom="1px solid #C4C4C4"
          v-for="(item, idx) in items"
          :key="idx"
        >
          <c-box
            :w="headings[0].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ numberingRow(idx) }}
          </c-box>
          <c-box
            :w="headings[1].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ item.couponName }}
          </c-box>
          <c-box
            :w="headings[2].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ item.clientName ? item.clientName : "-" }}
          </c-box>
          <c-box
            :w="headings[3].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
          >
            {{ item.productName }}
          </c-box>
          <c-box
            :w="headings[4].width"
            py="16px"
            px="14px"
            color="black.900"
            font-weight="normal"
            font-size="14px"
            line-height="21px"
            d="flex"
            align-items="center"
            textTransform="capitalize"
          >
            {{ item.productPrice ? item.productPrice : "-" }}
          </c-box>
        </c-grid>

        <c-flex v-if="!isEmpty" justify="space-between" py="28px" px="40px">
          <c-flex align="center">
            <c-text color="gray.900" fontSize="14px" lineHeight="21px">
              Show:
            </c-text>
            <c-select
              v-model="perPage"
              min-w="120px"
              border-radius="6px"
              fontSize="14px"
              ml="13px"
              size="md"
            >
              <option value="5">5 Rows</option>
              <option value="10">10 Rows</option>
              <option value="15">15 Rows</option>
              <option value="20">20 Rows</option>
            </c-select>
          </c-flex>

          <c-flex justifyContent="flex-end" w="100%">
            <Pagination
              :totalPages="totalPages"
              :perPage="parseInt(this.perPage)"
              :currentPage="currentPage"
              :maxVisibleButtons="3"
              @pagechanged="onChangePage"
            />
          </c-flex>
        </c-flex>
      </c-box>
    </c-box>
  </c-box>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";
import Pagination from "@/components/pagination";
export default {
  name: "DashboardCouponTracking",
  mixins: [generalMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      headings: [
        { label: "no", width: "74px" },
        { label: "jenis coupon", width: "200px" },
        { label: "klien", width: "195px" },
        { label: "program", width: "253px" },
        { label: "harga", width: "170px" },
      ],
      currentPage: 1,
      perPage: "5",
    };
  },
  computed: {
    ...mapState({
      items: (s) => s.suDashboard.coupons,
      meta: (s) => s.suDashboard.couponMeta,
    }),
    isEmpty() {
      return this.items.length === 0;
    },
    totalPages() {
      return Math.ceil(this.meta?.total / parseInt(this.perPage));
    },
  },
  watch: {
    perPage: {
      handler(val) {
        this.loadCouponsTracking({
          page: this.currentPage,
          perpage: parseInt(val),
        });
      },
    },
  },
  mounted() {
    this.loadCouponsTracking({
      page: this.currentPage,
      perpage: parseInt(this.perPage),
    });
  },
  methods: {
    ...mapActions({
      loadCouponsTracking: "suDashboard/listCouponsTracking",
    }),
    getFormatDate(date) {
      if (!date) return "-";
      moment.locale("id");
      return moment(date).format("D MMM yyyy");
    },
    numberingRow(index) {
      return this.currentPage * this.perPage - this.perPage + index + 1;
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadCouponsTracking({
        page: this.currentPage,
        perpage: parseInt(this.perPage),
      });
    },
  },
};
</script>